<template>
  <div class="align-center d-flex fill-height flex-column justify-center" style="row-gap: 50px; font-size: xx-large;">
    {{ $t('Wait for the redirection..') }}
    <v-progress-circular size="100" indeterminate color="primary" />
  </div>
</template>

<script>

export default {
  name: 'SAMLLoginPage',
  created(){
    this.generateSamlRequest();
  },
  methods:{
    async generateSamlRequest() {
      const params = new URLSearchParams(window.location.search);
      const issuer = params.get('issuer');

      if (!issuer) {
        throw new Error('Issuer parameter is missing in the URL');
      }

      await this.$axios.post(`/saml/generate-request?issuer=${issuer}`)
        .then((res) => {
          window.location.href = res.data.form
          // const samlForm = res.data.form; 
          // const form = document.createElement('form');
          // form.setAttribute('method', 'POST');
          // form.setAttribute('action', res.data.ssoUrl); // Replace with your IdP SSO URL

          // // Create the hidden input field for SAMLRequest
          // const input = document.createElement('input');
          // input.setAttribute('type', 'hidden');
          // input.setAttribute('name', 'SAMLRequest');
          // input.setAttribute('value', samlForm); // Set the base64 encoded SAMLRequest here

          // // Append the input to the form
          // form.appendChild(input);

          // // Append the form to the body
          // document.body.appendChild(form);

          // // Submit the form automatically
          // form.submit();
        })
        .catch((err) => {
          this.$error(err)
        })
    },
  }
}
</script>
